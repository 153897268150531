.admin-sidebar {
  width: 20%;
  height: 100%;
  background-color: #2a78aee8;
  display: inline-block;
  flex-direction: column;
  font-family: var(--mainText);
  color: #ffffff;
  padding: 50px 10px;
  position: fixed;
}
#root > div > div.admin-blogpost > div.admin-subs > form {
  margin-bottom: 10px;
}

.admin-sidebar p {
  cursor: pointer;
}
.admin-sidebar-container {
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.admin-profile {
  display: flex;
  width: 100%;
}
.sl-admin-burger {
  height: 30px;
  width: 40px;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.delete-one-photogallery {
  position: absolute;
  right: 0;
  margin-right: 5px;
  color: red;
}
.sl-admin-links {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: initial;
  transition: all 0.5s linear;
  list-style-type: none;
}

#root > div > div.admin-sidebar > div > ul {
  padding-top: 40px;
  padding-left: 35px;
}

.sl-admin-links li {
  text-align: justify;
  text-transform: capitalize;
}
.sl-admin-links p {
  display: block;
  width: max-content;
  text-decoration: none;
  color: var(--mainBlack);
  transition: all 0.3s ease;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--mainText) !important;
  text-transform: capitalize;
  margin-bottom: 30px !important;
}

.sl-show-admin {
  transform: translate(0) !important;
  transition: all 0.5s ease;
}
.admin-logout {
  margin: auto;
}
.admin-logout a {
  color: black;
}
.admin-container {
  display: flex;
}

.admin-blogpost {
  width: 80%;
  display: inline-block;
  position: absolute;
  right: 0;
}

.admin-view-blog-post {
  margin: auto;
  width: 85%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: 280px;
  margin-top: 30px !important;
}
.admin-blog-text-color {
  color: black !important;
  position: relative;
}

.admin-profile h3 {
  padding-left: 35px;
  font-size: 23px;
  margin-bottom: 0;
}

.container.margin-top {
  width: 90% !important;
}

.container.margin-top .form-group {
  margin-bottom: 1rem;
  margin-top: 30px;
}

.admin-blogpost label {
  font-weight: 600;
  font-size: 15px;
  font-family: var(--mainText);
  color: #222222;
  margin-top: 20px !important;
}

span.gray {
  font-family: var(--mainText);
  color: #6c757c !important;
  font-size: 15px;
}

.admin-blogpost {
  color: #222222;
}
.admin-bgg {
  content: "";
  background: hsla(0, 0%, 100%, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  padding: 50px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(5px);
}

.admin-blogpost h1 {
  text-align: justify !important;
  font-size: 30px;
  text-transform: uppercase;
}

.admin-blogpost div {
  margin: 0 !important;
  text-align: justify !important;
}

.admin-blogpost h4 {
  text-align: justify !important;
  text-transform: initial;
  font-family: var(--mainText);
  font-size: 16px;
  margin-bottom: 10px;
}

#article {
  margin-bottom: 30px;
}

.admin-container::before {
  content: "";
  display: block;
  background: url("../../img/admin-bg.jpg") center/cover no-repeat;
  width: 100% !important;
  height: 100% !important;
  position: fixed;
}

.container.margin-top {
  padding: 70px;
  color: #222222 !important;
  position: relative;
}

.admin-view-blog-post {
  padding: 70px;
}

.admin-subs {
  position: relative;
  padding: 70px;
}

.our-blog-img-gallery img {
  z-index: 0;
}

.our-blog-text {
  width: 100%;
  padding: 20px;
  text-align: left;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4) !important;
  height: 100%;
}

.dropdown-heading {
  position: relative;
}

#root > div > div.admin-blogpost > div.admin-subs > div > h4 > p:hover {
  background: #2978b0;
  color: #ffffff;
}

.admin-blogpost div {
  margin: 0 !important;
  text-align: justify !important;
  justify-items: start !important;
  align-items: start;
}

.book-now-modal-container {
  background: white;
  position: fixed;
  padding: 30px 20px;
  padding: 60px;
  padding-bottom: 0;
  padding-top: 0;
  top: 30%;
}

#root > div > div.admin-blogpost > div.admin-subs > div > h4 > p {
  padding: 10px 0px;
  width: 98px;
  text-align: center;
  position: relative;
  top: -12px;
  font-size: 13px;
  text-transform: lowercase;
  border: 2px solid #2978b0 !important;
  border-radius: 4px;
  background: #ffffff;
  color: #2978b0;
  font-family: var(--mainText) !important;
}

.admin-subs h4 {
  position: relative;
  top: 15px;
}

.admin-blogpost .book-now-modal-container {
  max-width: 330px !important;
}

.booking-list > div > h4:nth-child(5) > p:hover {
  background: #2978b0;
  color: #ffffff;
  border: 2px solid #2978b0;
}
.booking-list > div > h4:nth-child(5) > p {
  background: #ffffff;
  text-align: center;
  padding: 7px;
  border-radius: 4px;
  color: #2978b0;
  position: relative;
  font-family: var(--mainText) !important;
  top: -7px;
  border: 2px solid;
  cursor: pointer;
}

.list-of-contacts > div > h4:nth-child(5) > p:hover {
  background: #2978b0;
  color: #ffffff;
  border: 2px solid #2978b0;
}
.list-of-contacts > div > h4:nth-child(5) > p {
  background: #ffffff;
  text-align: center;
  padding: 7px;
  border-radius: 4px;
  color: #2978b0;
  position: relative;
  font-family: var(--mainText) !important;
  top: -7px;
  border: 2px solid;
  cursor: pointer;
}

.admin-profile h3 {
  padding-left: 11px;
  font-size: 23px;
  margin-bottom: 34px;
}
.admin-blogpost .book-now-modal {
  z-index: 2;
  overflow: hidden;
}

.admin-blogpost .book-now-modal-container {
  max-width: 330px !important;
  top: 0;
  bottom: 0;
  transform: none;
  left: 0;
  right: 0;
  margin: auto !important;
}

.admin-subs h2 {
  font-weight: 600;
  font-size: 15px;
  font-family: var(--mainText);
  color: #222222;
  margin-top: 20px !important;
}

.admin-subs h1 {
  text-align: justify !important;
  font-size: 24px !important;
  text-transform: uppercase;
}

.admin-blogpost .book-now-modal {
  z-index: 99999;
  overflow: visible;
}
.admin-blogpost .book-now-modal-container {
  max-width: 380px !important;
  top: 5%;
  bottom: 0;
  transform: none;
  left: 0;
  right: 0;
  border-radius: 10px;
  height: auto;
  margin: auto !important;
  z-index: 999999999;
  position: relative;
  background: #ffffffe6;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

#root > div > div.admin-sidebar > div > ul > li:nth-child(6) > a {
  color: #ffffff;
}

#root > div > div.admin-blogpost > div.admin-subs > div > div > div {
  width: 100%;
  height: 185px;
  overflow: hidden;
}

#root > div > div.admin-blogpost > div.admin-subs > form {
  margin-bottom: 30px !important;
  padding: 30px;
  margin: auto;
  text-align: center;
  border: 1px solid #2222;
  border-radius: 10px;
}

.admin-blogpost div {
  margin: 0 !important;
  text-align: justify !important;
  justify-items: start !important;
  align-items: start;
  margin: auto !important;
}

.delete-one-photogallery {
  position: absolute;
  right: -6px;
  margin-right: 5px;
  color: #22222296;
  background: #ffffff94;
  padding: 3px 10px;
}

.react-confirm-alert-overlay {
  background: rgb(255 255 255 / 30%) !important;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
}

#root > div > div.admin-blogpost > div.admin-subs > form input[type="submit"] {
  background: #ff0000;
  margin-top: 15px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 30px;
  color: #ffffff;
  border-color: transparent;
  border-radius: 6px;
}

@media screen and (max-width: 1190px) {
  .booking-list h4:nth-child(3) {
    display: none;
  }
  .list-of-contacts > div > h4:nth-child(3) {
    display: none;
  }
  .react-confirm-alert-body {
    text-align: center !important;
    width: 100%;
  }
  .react-confirm-alert-button-group {
    text-align: center;
    width: 122px !important;
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 980px) {
  .booking-list h4:nth-child(2) {
    display: none;
  }
  .list-of-contacts > div > h4:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 535px) {
  .booking-list h4:nth-child(4) {
    display: none;
  }
  label {
    display: block;
  }

  .list-of-contacts > div > h4:nth-child(4) {
    display: none;
  }
  #root
    > div
    > div.admin-blogpost
    > div.admin-subs
    > form
    input[type="submit"] {
    position: absolute;
    top: 100px;
    right: 21px;
  }

  .admin-blogpost label {
    font-weight: 600;
    font-size: 15px;
    font-family: var(--mainText);
    color: #222222;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 800px) {
  .sl-admin-links {
    background: #2977b0;
    position: absolute;
    height: 100vh;
    left: 0px;
    text-transform: uppercase;
    display: block;
    font-family: var(--mainText);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-100%);
    top: 64px !important;
    z-index: 100;
    color: #ffffff !important;
    padding-left: 0 !important;
    padding-top: 25px !important;
  }

  .sl-admin-links li:hover,
  .sl-admin-links li a:hover {
    color: #2978b0 !important;
  }

  .admin-container {
    display: block;
  }
  .admin-sidebar {
    width: 100% !important;
    height: 65px;
    display: block;
    z-index: 9;
    padding: 15px;
    background-color: rgba(42, 120, 174);
  }
  .admin-sidebar-container {
    width: 100%;
  }
  .admin-sidebar-menu {
    display: flex;
    flex-direction: column;
    color: black;
  }
  .sl-admin-burger {
    display: flex;
  }

  .admin-blogpost {
    width: 100%;
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
  }

  .admin-subs {
    position: relative;
    padding: 0;
    padding-top: 60px;
    width: 90% !important;
  }

  .container.margin-top {
    padding: 0;
    color: #222222 !important;
    position: relative;
    top: 60px;
    margin: auto !important;
    margin-bottom: 50px !important;
  }

  .admin-blogpost h1 {
    text-align: justify !important;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 0px;
    text-align: justify !important;
  }

  .sl-admin-links p {
    display: block;
    width: max-content;
    text-decoration: none;
    transition: all 0.3s ease;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--mainText) !important;
    text-transform: capitalize;
    margin-bottom: 22px !important;
    text-align: center !important;
    margin: auto;
    padding: 0 !important;
    text-align: center !important;
  }

  .sl-admin-links li {
    text-align: center !important;
  }
  .sl-admin-burger .line1,
  .sl-admin-burger .line2,
  .sl-admin-burger .line3 {
    background-color: #ffffff !important;
    width: 30px;
    height: 3px;
    margin: 3px;
    border: 1px solid #ffffff !important;
    transition: all 0.3s linear;
  }

  .admin-view-blog-post {
    padding: 0;
    color: #222222 !important;
    position: relative;
    top: 100px;
    margin: auto !important;
    margin-bottom: 50px !important;
  }

  .admin-blogpost div {
    margin: auto !important;
    text-align: justify !important;
  }

  .admin-profile h3 {
    padding-left: 10px;
    font-size: 24px;
    width: 100%;
    top: 4px;
    position: relative;
  }
  .admin-blogpost div {
    text-align: justify !important;
    width: 100%;
  }
  .admin-view-blog-post {
    padding: 0px;
    color: #222222 !important;
    position: relative;
    top: 100px;
    margin: auto !important;
    margin-bottom: 50px !important;
  }
  .admin-profile {
    display: flex;
    width: 100%;
  }
  .toggle.line1 {
    transform: rotate(-50deg) translate(-5px, 3px);
  }
  .sl-admin-links li,
  .sl-admin-links li a {
    text-align: justify;
    text-transform: capitalize;
    text-align: center;
    color: #ffffff !important;
  }

  .admin-subs h4:nth-child(2) {
    text-align: center !important;
  }

  .admin-subs h4 {
    width: 100% !important;
    margin: 6px !important;
    margin-left: 0 !important;
    margin-right: 4px;
  }
  .our-blog-img-gallery {
    width: 90%;
    position: relative;
    color: var(--mainWhite);
    overflow: hidden;
  }
  .our-blog-img-gallery img {
    position: absolute;
    height: auto;
    overflow: hidden;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
    width: 660px;
  }

  .admin-blogpost div {
    z-index: 2;
  }
  .sl-admin-links {
    width: 35%;
  }

  .booking-list > div {
    height: 56px;
  }
  .list-of-subs > div {
    height: 40px;
  }
  .list-of-contacts > div {
    height: 40px;
  }
}

@media screen and (max-width: 600px) {
  .admin-container::before {
    background-position-x: -448px;
  }

  #root > div > div.admin-blogpost > div.admin-subs > div > h4 > p {
    float: right;
  }

  .admin-blogpost h1 {
    text-align: justify !important;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 0px;
    text-align: justify !important;
  }
  .admin-subs {
    width: 85% !important;
  }
}
