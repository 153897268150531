.gallery-container {
  margin: auto;
  width: 85%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.gallery-box {
  position: relative;
  justify-self: center;
  align-self: center;
  margin-bottom: 20px;
}
.gallery-box img {
  width: 100%;
}

.admin-gallery-view {
  margin-top: 20px !important;
}

.gallery-modal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.gallery-modal-container span {
  color: #ffffff;
}

.gallery-img {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .gallery-img-show {
    width: 70%;
  }
}
