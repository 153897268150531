.book-now-modal {
  z-index: 100;
  background: rgba(126, 126, 126, 0.6);
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#root
  > div.footer-container
  > div.button-fixed
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > select {
  width: 50%;
}
.book-now-modal-container {
  background: white;
  position: fixed;
  top: 43%;
  left: 50%;
  right: 0;
  bottom: 0;
  max-width: 800px;
  height: 500px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.book-now-datetime {
  width: 100%;
  height: auto;
  text-align-last: center;
}
.book-now-datetime div {
  text-align: center;
}

.book-now-input {
  width: 50%;
  height: 100%;
  border: 1px solid black;
}
.form-time-pick-container {
  display: flex;
  justify-content: center;
}
.form-time-pick-morning {
  width: 100%;
}
.form-time-pick-afternoon {
  width: 100%;
}
.form-time-pick-gird {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 100px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: 40px;
}
.form-time-pick-active {
  background-color: var(--mainRed);
}

.book-now-form-info {
  display: flex;
  flex-direction: column;
}

h1.pick {
  text-transform: uppercase;
  color: #222;
  font-size: 1rem;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
}

.book-now-form-info input {
  display: inline-block;
  margin-bottom: 0 !important;
  font-size: 14px !important;
}

.book-now-submit-button {
  border-radius: 4px;
  padding: 8px 15px !important;
}

.book-now-modal-container {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  max-width: 800px;
  height: 500px;
  transform: translate(-50%, -50%);
  justify-content: center;
  justify-items: center;
  padding: 30px 20px;
  width: 90% !important;
  align-items: center;
}

.form-row {
  width: 100%;
  margin: auto;
}

.form-row .form-group.col-md-6 {
  width: 48%;
  padding: 0;
}

.footer-container input[type="text"] {
  width: 100%;
  margin: 0 !important;
}

.form-group.col-md-6:nth-child(2) {
  left: 10px;
}

.form-group.col-md-4 select#stateValue {
  font-size: 14px;
  color: #6c757c;
}

.form-group.col-md-4 {
  width: 50%;
  padding: 0;
  left: 6px;
  font-family: var(--mainText);
  color: #9c9999 !important;
}

.form-group.col-md-2 {
  padding-left: 0;
  width: 50%;
}

.book-now-modal-container {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  max-width: 800px;
  height: 65%;
  transform: translate(-50%, -50%);
  justify-content: center;
  justify-items: center;
  padding: 30px 20px;
  width: 90% !important;
  align-items: center;
}

.form-group.col-md-2 {
  padding-left: 0;
  width: 48%;
  display: block;
}

.book-now-form-info button {
  padding: 8px 15px;
  font-size: 14px;
  font-family: var(--mainText);
  text-transform: uppercase;
  border-radius: 4px;
  text-align: center;
  background: var(--secondaryColor);
  color: #ffffff;
  border-color: transparent;
}
.form-info-buttons {
  margin: auto;
}
.form-info-buttons button {
  margin: 0 5px;
}

#root > div.footer-container > div.button-fixed > div > div > div:nth-child(3) {
  margin-top: 3px;
  flex-direction: column;
}
.form-time-pick-button {
  background: var(--secondaryColor);
  margin: 5px auto;
  border: 1px;
  color: #ffffff;
  padding: 10px;
  font-family: var(--mainText);
  display: block;
}
input[type="submit"] {
  background: #ff0000;
  margin-top: 15px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 15px;
  color: #ffffff;
  border-color: transparent;
}

.book-now-modal-container > .book-now-datetime > div > button {
  display: block;
  background: #2978b0;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
}

#root
  > div.footer-container
  > div.button-fixed
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > select {
  width: 154px;
}

.react-datepicker {
  width: 85%;
}

.react-datepicker__month-container {
  width: 100%;
}
#root
  > div
  > div.footer-container
  > div.button-fixed
  > div
  > div
  > div:nth-child(3)
  > div
  > button {
  margin-top: 27px;
  padding: 10px;
  font-family: var(--mainText);
  text-transform: uppercase;
}

select.form-control {
  width: 120px;
  position: relative;
  left: 10px;
}

.form-time-pick-container {
  width: 42%;
  margin-top: 12px;
  left: 0;
  float: left;
}

.form-time-pick-container p {
  font-size: 18px;
  font-family: "Fjalla One", sans-serif !important;
  text-transform: uppercase;
  top: 8px;
  color: #222222;
  position: relative;
}

.book-now-datetime {
  width: 100%;
}

div.button-fixed > div > div > div:nth-child(3) > div {
  width: 85%;
  margin: auto;
  text-align: justify !important;
}

.form-time-pick-container {
  text-align: justify !important;
  float: left;
  width: 159px;
}

#root
  > div
  > div.footer-container
  > div.button-fixed
  > div
  > div
  > div:nth-child(3)
  > div
  > button {
  margin-top: 27px;
  padding: 10px;
  font-family: var(--mainText);
  text-transform: uppercase;
  float: right;
  top: 8px;
  position: relative;
}

.book-now-modal-container {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  max-width: 800px;
  height: 460px;
  transform: translate(-50%, -50%);
  justify-content: center;
  justify-items: center;
  padding: 30px 20px;
  width: 90% !important;
  align-items: center;
}

.book-now-form-info {
  display: flex;
  width: 80%;
  flex-direction: column;
}

div.button-fixed > div > div > div:nth-child(3) > div {
  width: 36%;
  margin: auto;
  text-align: justify !important;
  position: relative;
  top: 7px;
}

.form-info-buttons {
  margin: auto;
  position: absolute;
  bottom: 55px;
  right: 60px;
}

.form-time-pick-container {
  text-align: justify !important;
  float: left;
  width: 159px;
  position: absolute;
  left: 9%;
}

.book-now-form-info > * {
  text-align: justify !important;
  margin-left: 0;
  align-items: self-end;
}
