:root {
  --mainBlue: #2a78ae;
  --mainDark: #000000;
  --secondDark: #222222;
  --mainWhite: #ffffff;
  --secondarywhite: #ffffffeb;
  --secondaryColor: #F58800;
  --mainRed: #ff0000;
  --mainText: "Fjalla One", sans-serif !important;
  --secondaryText: "Source Sans Pro" !important;
}

.hover-button {
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0%;
  height: 86%;
  background: #2978b0db;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

a.slearn-more {
  margin-top: 15px !important;
  padding-left: 17px;
}

.hover-button:hover {
  opacity: 1 !important;
}

a:active,
a:focus {
  color: var(--mainBlue) !important;
}

a {
  transition: all 0.5s ease-in-out;
  color: #444;
}

button {
  transition: all 0.5s ease-in-out !important;
}

a.sbook-now:hover {
  background-color: var(--secondaryColor);
  color: #ffffff !important;
  transition: all 0.5s linear;
}
a.sbook-now {
  background-color: #ffffffff;
  color: #2978b0;
  width: 147px !important;
  padding: 8px;
  margin-bottom: 8px;
  text-decoration: none !important;
  border-radius: 10px;
  transition: all 0.5 linear;
}

a.slearn-more:hover {
  text-decoration: none !important;
  color: var(--secondaryColor) !important;
  transition: all 0.5s linear;
}

.sbook-now,
.slearn-more {
  position: relative;
  left: auto !important;
  margin: auto;
  text-align: center;
  display: block;
  width: auto !important;
  font-size: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  font-family: var(--mainText);
  top: 36%;
}

.dark-background {
  background: #000000;
}
.light-background {
  background: #ffffff;
}

button {
  border-radius: 4px;
}

button.btn.btn-danger.text-uppercase:hover {
  border-color: #ffffff !important;
  background: #ffffff !important;
  color: var(--mainBlue);
}

button.react-datepicker__navigation:hover {
  color: var(--mainBlue) !important;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  color:#444 !important;
}
.bg-color-light {
  background-color: var(
    --secondarywhite
  ) !important;
}
.phone-number {
  font-family: var(--mainText);
  color: var(--mainDark) !important;
  text-decoration: none !important;
}
.services-mb {
  margin-bottom: 40px !important;
}

.hold-services {
  position: relative;
  cursor: pointer;
  height: 300px;
  margin: 15px 0;
}

.hold-services:hover {
  color: #2978b0 !important;
}

.btn-book:hover {
  background: var(
    --mainBlue
  ) !important;
  border-color: var(--mainBlue);
}

a.nav-link {
  text-transform: uppercase;
  font-size: var(
    --firstFont
  ) !important;
}
.btn-secondary {
  background: var(--secondaryColor) !important;
}
.btn-book {
  font-size: 1rem !important;
  padding: 0.5rem;
  width: 7rem !important;
  background: var(--secondaryColor);
  color: var(--mainWhite);
  font-family: var(--mainText);
  border: 1px solid var(--secondaryColor);
  border-radius: 4px !important;
}

.arrow {
  font-size: 1.4rem;
}
.main-dark {
  color: var(--mainDark);
}
.main-blue {
  color: var(--mainBlue);
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: var(--mainText);
  padding: 7px 0;
}

h5 {
  color: var(--mainBlue);
  font-family: var(--mainText);
}
p {
  font-family: var(
    --secondaryText
  ) !important;
}

.py-15 {
  padding: 15px auto !important;
}
.margin-top {
  margin-top: 97px;
}
.mainText {
  font-family: var(
    --mainText
  ) !important;
}
.secondaryText {
  font-family: var(
    --secondaryText
  ) !important;
}
.mt-15 {
  margin-top: 15px !important;
}

.container-box {
  max-width: 85%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  margin-top: 50px;
  align-items: center;
}
.btn-danger {
  background: var(
    --secondaryColor
  ) !important;
}
.btn-book {
  font-size: 1.2rem;
  padding: 0.5rem;
  width: 12rem;
  border-radius: 4px !important;
  background: var(--secondaryColor);
  color: var(--mainWhite);
  font-family: var(--mainText);
  border: 1px solid var(--secondaryColor);
  margin: auto !important;
}

.text-uppercase {
  text-transform: uppercase !important;
  margin-bottom: 0 !important;
}

p.text-uppercase.mainText:active {
  color: var(--mainBlue);
}
p.text-uppercase.mainText {
  cursor: pointer;
}
p.text-uppercase.mainText:hover {
  color: var(--mainBlue);
}

.navbar-logo {
  margin-right: 0 !important;
  width: 120px;
}

.nav-link {
  color: var(--mainDark) !important;
  font-family: var(
    --mainText
  ) !important;
}

.icon-logo img {
  width: 8rem !important;
}
/*----------------navbar---------------*/
.sl-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 10px;
  background: var(--mainWhite);
  z-index: 100;
  margin: auto !important;
}
.sl-nav-logo {
  width: 140px;
  position: relative;
  top: 6px;
  margin: auto;
  opacity: 0.8;
}

.services-img {
 opacity: 0.8;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0rem;
}

.sl-nav-header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}
.sl-nav-center {
  margin: auto;
  max-width: 1680px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 94%;
  margin: auto;
  font-family: var(--mainText);
}

.sl-nav-burger {
  height: 40px;
  width: 40px;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.line1 {
  background-color: black;
  width: 30px;
  height: 3px;
  margin: 3px;
  border: 1px solid black;
  transition: all 0.3s linear;
}
.line2 {
  background-color: black;
  width: 30px;
  height: 3px;
  margin: 3px;
  border: 1px solid black;
  transition: all 0.3s linear;
}
.line3 {
  background-color: black;
  width: 30px;
  height: 3px;
  margin: 3px;
  border: 1px solid black;
  transition: all 0.3s linear;
}
.toggle.line1 {
  transform: rotate(-45deg)
    translate(-5px, 6px);
}
.toggle.line2 {
  display: none;
}
.toggle.line3.wew {
  transform: rotate(49deg)
    translate(-8px, -7px) !important;
}
.sl-nav-links {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.5s linear;
  list-style-type: none;
}

.sl-nav-links li {
  margin-left: 3.5rem;
  text-align: center;
}
.sl-nav-links a {
  display: block;
  width: max-content;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.sl-nav-links .nav-item-active {
  color: var(--mainBlue);
  font-size: 18px;
}
.sl-show-nav {
  transform: translate(0) !important;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
ul.sl-show-nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#carouselExampleIndicators
  > div
  > div
  > p:nth-child(2) {
  font-size: 18px;
  font-family: var(
    --mainText
  ) !important;
}

@media screen and (max-width: 1024px) {
  .btn-book {
    display: none;
  }
  .sl-nav-header {
    width: 100%;
  }
  .sl-nav-burger {
    display: flex;
  }
  .sl-nav-links {
    background: var(--mainWhite);
    position: absolute;
    height: 90vh;
    top: 93px !important;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translate(100%);
  }
  .sl-nav-links li {
    margin-left: 0px;
    text-align: center;
  }
}

/* .nnavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--mainWhite);
  z-index: 100;
}
.nav-header {
  display: flex;
  justify-content: space-around;
  justify-items: center;
}
nav .nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
nav .nav-icon {
  font-size: 1.5rem;
  color: var(--mainBlack);
}
nav .nav-links {
  height: 0;
  overflow: hidden;
  transition: all 0.5s linear;
  list-style-type: none;
  margin-top: 15px;
}

nav .nav-links a {
  display: block;
  text-decoration: none;
  color: var(--mainBlack);
  transition: all 0.3s ease;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}
nav .nav-links a:hover {
  color: var(--primaryColor);
}

nav .show-nav {
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}

@media screen and (min-width: 856px) {
  nav .nav-btn {
    display: none;
    transition: all 0.5s linear;
  }
  nav .nav-center {
    margin: 0 auto;
    display: flex;
  }
  nav .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
    justify-items: center;
  }
} */
/*-----------end-of-navbar----------*/
.defaultHero {
  height: 629px;
  width: 100%;
  background: url("./img/sl-roof-banner.jpg")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 4rem;
  z-index: 0;
  opacity: 0.9;
}

.ourServicesHero {
  height: 200px;
  width: 100%;
  background-color: var(
    --mainDark
  ) !important;
  background: url("./img/thumbnail-1.png")
    center/contain no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 6rem;
}
.blogBan {
  background: url("./img/our-blog-banner.jpg")
    center/cover no-repeat;
}

.aboutBan {
  background: url("./img/covered.png")
    center/cover no-repeat;
}
.blogHero {
  height: 350px;
  width: 100%;
  background-color: var(
    --mainDark
  ) !important;
  background-position-y: 47% !important;
  background: url("./img/our-services.png")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 6rem;
}
.galleryHero {
  height: 350px;
  width: 100%;
  background-color: var(
    --mainDark
  ) !important;
  background-position-y: 47% !important;
  background: url("./img/gallery-featured.jpeg")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 6rem;
}
header.contactBan.banner-tempt {
  background: url("./img/contact-featured.png")
    center/cover no-repeat;
}

h1.text-uppercase.text-center,
h3.text-capitalize.text-center {
  color: #ffffff;
}

header.banner-tempt {
  height: 350px;
  width: 100%;
  background-color: var(
    --mainDark
  ) !important;
  background-position-y: 47% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 4rem;
}

header {
  margin-top: 4rem !important;
}

.blogBanner {
  width: 100%;
  height: 100%;
  color: var(--mainWhite);
  font-family: var(--mainText);
  background-color: rgba(0, 0, 0, 0.3);
}
.blog-category-cointainer {
  margin: 0 auto;
}
.blog-category {
  margin: auto;
}
.blog-category select {
  height: 2rem;
  width: 250px !important;
}
.our-blog-gallery {
  justify-content: center;
  height: auto;
  margin: auto;
  max-width: 85%;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 400px)
  );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: 350px;
  margin-top: 30px !important;
}

.our-blog-text {
  color: var(--mainWhite) !important;
  width: 100%;
  padding: 20px;
  text-align: left;
  position: absolute;
  bottom: 0;
}
.our-blog-text-title {
  color: var(--mainWhite) !important;
  font-size: 18px !important;
}
.our-blog-img-gallery {
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--mainWhite);
}
.our-blog-img-gallery img {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: -1;
}
.our-blog-img-gallery h3,
p {
  font-family: var(--mainText);
}
.banner {
  position: absolute;
  background: url("./img/blue-overlay-banner.png")
    center/cover no-repeat;
  height: 629px;
  width: 100%;
  transform: translate(-25%);
  opacity: 0.8;
}

.banner-container {
  margin: auto;
  width: 85%;
  position: absolute;
  z-index: 100;
}

.book-an-appointment {
  cursor: pointer;
  z-index: 1000;
}

.box-container {
  width: 39rem;
}
.box-container h1 {
  color: var(--mainWhite);
  font-family: var(--mainText);
  font-size: 6rem;
}
.box-container p {
  margin: 45px 0px !important;
  color: var(--mainWhite);
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-family: var(--secondaryText);
}

.text-sm {
  font-size: 0.9rem !important;
}
.btn-danger {
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 8px 15px !important;
  font-family: var(--mainText);
  border-color: transparent;
}

.button-container {
  margin-top: 30px;
}

/* Our services */
#root
  > div
  > div:nth-child(3)
  > div.container-fluid.mb-5
  > div
  > a:nth-child(5)
  > p {
  font-size: 17px;
}
.hover-button-services {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.hover-button-services:hover {
  opacity: 1 !important;
  background: #2978b0db !important;
}
.ourServicesFont {
  font-size: 1.2rem;
  font-family: var(--mainText);
}
.service-gallery {
  margin: auto;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(370px, 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
#root
  > div
  > div:nth-child(3)
  > div.container-fluid.mb-5.my-15 {
  margin-bottom: 60px !important;
  max-width: 1680px !important;
}
.our-service-gallery {
  margin: auto;
  width: 85%;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(290px, 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

#root
  > div:nth-child(3)
  > div.container-fluid.mb-5
  > div
  > a:nth-child(5)
  > img {
  /* width: 100% !important; */
  height: 100%;
}

.our-service-gallery img {
  width: 100%;
}
.our-service-gallery div {
  margin: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.ourServicesFont {
  font-size: 1.2rem;
  font-family: var(
    --mainText
  ) !important;
}

/* subsribe */

h1.text-center.text-capitalize.mainText {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.subscribe-box {
  width: 50rem;
  height: 100%;
  color: var(--mainWhite);
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.subscribe-box h1 {
  font-family: var(--mainText);
  padding-bottom: 0 !important;
}
.subscribe-box p {
  font-weight: var(--secondaryText);
  padding: 0px 0;
  width: 90%;
  margin: auto;
}

div#carouselExampleIndicators {
  height: 230px !important;
}

.subscribe-container
  input[type="text"] {
  font-family: var(--mainText);
  width: 22rem;
  padding: 10px 10px;
  font-size: 16px !important;
  border: none;
}

.subscribe-container button {
  padding: 7px 8px;
  margin-top: 2px;
  background: var(--mainRed);
  color: var(--mainWhite);
  font-family: var(
    --mainText
  ) !important;
  font-size: 15.4px;
  left: 17px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

.subscribe-container button:hover {
  background: #ccc;
}

.row {
  margin: 40px auto;
}

.banner-container {
  width: 85% !important;
}

.footer-box-container {
  width: 100%;
}
.owner-image-two {
  display: none;
}

#inputPassword4 {
  width: 100%;
}
/* --------------------------------footer --------------------------------*/

.footer-container {
  background: var(--secondDark);
  width: 100%;
}

.footer-box {
  height: 87px;
}

.footer-box-container {
  max-width: 1680px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.footer-container h4 {
  font-size: 1.2rem;
  color: #b7b7b7;
}
.footer-container .column {
  width: 400px !important;
  margin: 4rem auto;
}
.footer-container input[type="text"] {
  width: 225px;
  margin: 0 !important;
}

.footer-copy-right {
  width: 480px;
}

p.text-capitalize.text-center {
  font-family: var(
    --secondaryText
  ) !important;
  font-size: 13px;
  text-transform: capitalize !important;
  text-align: justify !important;
  padding-left: 62px;
  color: #b7b7b7 !important;
}

.secondaryText {
  font-family: var(
    --mainText
  ) !important;
  font-size: 14px !important;
  line-height: 26px;
}

h4.secondaryText {
  margin-bottom: 0px;
}

h4.secondaryText {
  margin-bottom: 0px;
  color: #b7b7b7;
  font-family: var(
    --secondaryText
  ) !important;
  line-height: 22px;
}

.footer-container .subscribe-container {
  margin-top: 8px;
}

.footer-container input[type="text"] {
  width: 145px;
  margin: 0 !important;
}

.footer-container
  .subscribe-container
  input[type="text"] {
  font-family: var(--mainText);
  width: 141px;
  padding: 10px 10px;
  margin-top: 0px !important;
  font-size: 12px !important;
  border: none;
  font-size: 12px;
  border-radius: 0 !important;
}
.footer-container
  .subscribe-container
  button {
  padding: 10px 10px;
  padding-bottom: 11px;
  margin-top: 2px;
  background: var(--mainRed);
  color: var(--mainWhite);
  font-family: var(
    --mainText
  ) !important;
  left: 17px;
  font-size: 12px;
  border: none;
  cursor: pointer !important;
  border-radius: 0 5px 5px 0;
}

.footer-copy-right {
  width: 450px;
  text-align: center;
  float: none;
  margin: auto;
  position: initial;
  margin-top: 70px;
}

.column.footer-box {
  margin: auto;
  display: block;
  width: 280px !important;
  margin: 0 50px;
}

/*------------LOGIN------------*/

.login-container {
  background-color: rgba(
    255,
    255,
    255,
    0.83
  );
  width: 550px;
  height: 475px;
  box-shadow: 15px 15px 25px
    rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  padding: 58px;
  left: 26%;
  transform: translate(-50%, -50%);
}

main {
  background-color: gray;
  background: url("./img/gallery-featured.jpeg")
    center/cover no-repeat;
  width: 100%;
  height: 100vh;
  font-family: var(
    --mainText
  ) !important;
}
.btn-primary {
  background-color: var(
    --mainBlue
  ) !important;
  border-color: var(
    --mainBlue
  ) !important;
}
#root
  > main
  > div
  > form
  > div.form-group.text-center.mt-3
  > button {
  width: 175px;
  padding: 10px;
  margin-top: 15px;
}
#root
  > div:nth-child(2)
  > div:nth-child(2)
  > div.container.mx-15
  > div
  > div {
  display: none;
}

.carousel-inner.container-box {
  margin-top: 0;
}

/*------------/LOGIN/-----------*/

/*------------/about us/-----------*/

.blogBanner {
  background: #00000066;
}

.who-we-are {
  margin: auto;
  width: 85%;
  padding: 50px 0;
  text-align: center;
}
h5 {
  text-transform: uppercase;
}

h3 {
  text-transform: uppercase;
  font-family: var(--mainText);
  margin-bottom: 15px;
}

.ownerCover {
  background: #2978b0;
}

.who-we-are p {
  text-align: justify;
  margin-bottom: 0px;
}

.the-owner {
  width: 85%;
  margin: auto;
  color: #fff;
  padding: 50px 0;
}

.owner-description {
  width: 60%;
  display: inline-block;
}

.owner-image {
  margin-left: 60px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 30%;
  float: none;
}

.owner-image img {
  width: 80%;
  float: left;
  top: 0;
  position: relative;
}

#root
  > div:nth-child(2)
  > div:nth-child(3)
  > div.got-you-covered
  > div.about-description
  > button {
  width: auto !important;
  text-align: justify;
  margin: 20px 0 !important;
  padding: 15px 25px;
}

.got-you-covered {
  background: #f2f2f2;
}

.left-cov {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.left-cov img {
  width: 99%;
}

.about-description {
  width: 47%;
  display: inline-block;
  padding: 50px 50px;
  margin-top: 10px;
}

#root
  > div:nth-child(2)
  > div:nth-child(3)
  > div.my-15 {
  margin-bottom: 4rem !important;
}

h1 {
  font-size: 3.5rem;
  font-weight: 600;
  font-family: var(--mainText);
  padding: 7px 0;
}

h2.text-center.text-capitalize.mainText {
  text-transform: uppercase !important;
}

header.banner-tempt {
  height: 350px;
  width: 100%;
  background-color: var(
    --mainDark
  ) !important;
  background-position-y: 47% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 4rem;
}

.button-fixed {
  display: none;
}

nav.sl-navbar {
  padding: 0;
}
span.contact {
  margin-left: 8px;
}
.container-fluid {
  padding: 0 !important;
}

.findLocation {
  width: 85%;
}

h5,
h3 {
  text-align: center;
}

.blog-category {
  margin-top: 30px;
}

h4.ml-auto {
  font-family: var(
    --mainText
  ) !important;
  font-size: 18px;
  text-transform: uppercase;
  padding-top: 10px;
  margin-right: 10px;
}

.our-blog-text h2 {
  font-size: 25px;
  font-family: var(--mainText);
  text-transform: capitalize;
}

.subscribe {
  background: url("./img/subscribe-bg.jpg")
    center/cover no-repeat;
  height: auto;
  width: 100%;
}

.subscribe-box h5 {
  color: var(--mainWhite);
  font-family: var(--mainText);
}

.my-15 {
  margin: 50px auto !important;
}

p {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.main-blue {
  color: var(--mainBlue);
  font-size: 20px;
  font-family: var(--mainText);
  text-transform: uppercase;
}

.blog-category-cointainer h1 {
  font-size: 2.5rem;
}

.blogCategStyle {
  position: absolute;
  right: 0%;
  color: white;
  text-transform: uppercase;
  width: 250px;
  top: 20%;
  padding: 5px 28px;
  background: #ffffff33;
}

.banner-attr {
  height: 220px;
  width: 100%;
  margin-top: 5rem;
  background: #1279af99;
}

.blog-category-cointainer {
  width: 85%;
}

.ContactBanner {
  background: #00000082;
  width: 100%;
  height: 100%;
}

/**contact-thumbnail**/

.fl-row,
.fl-row-content,
.fl-col-group,
.fl-col,
.fl-module,
.fl-module-content {
  zoom: 1;
}
.fl-builder-content *,
.fl-builder-content *:before,
.fl-builder-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fl-row:before,
.fl-row:after,
.fl-row-content:before,
.fl-row-content:after,
.fl-col-group:before,
.fl-col-group:after,
.fl-col:before,
.fl-col:after,
.fl-module:before,
.fl-module:after,
.fl-module-content:before,
.fl-module-content:after {
  display: table;
  content: " ";
}
.fl-builder-content *,
.fl-builder-content *:before,
.fl-builder-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fl-module-content {
  margin: 20px;
}

.fl-row:before,
.fl-row:after,
.fl-row-content:before,
.fl-row-content:after,
.fl-col-group:before,
.fl-col-group:after,
.fl-col:before,
.fl-col:after,
.fl-module:before,
.fl-module:after,
.fl-module-content:before,
.fl-module-content:after {
  display: table;
  content: " ";
}
.uabb-info-list-wrapper.uabb-info-list-top {
  display: block;
}
.uabb-info-list
  .uabb-info-list-wrapper {
  margin: 0;
  padding: 0;
}

.uabb-info-list-content-wrapper {
  position: relative;
  z-index: 5;
}
.fl-clearfix {
  zoom: 1;
}

.uabb-info-list-wrapper.uabb-info-list-top
  li
  .uabb-info-list-icon {
  float: none;
  margin: 0 auto;
}
.uabb-info-list-content-wrapper.uabb-info-list-top
  .uabb-info-list-icon,
.uabb-info-list-content-wrapper.uabb-info-list-top
  .uabb-info-list-content {
  display: block;
}
.uabb-info-list-icon {
  position: relative;
  z-index: 5;
}

.uabb-info-list-wrapper.uabb-info-list-top {
  display: block;
}
.uabb-info-list
  .uabb-info-list-wrapper {
  margin: 0;
  padding: 0;
}

.uabb-info-list-content-wrapper {
  position: relative;
  z-index: 5;
}

.uabb-info-list-wrapper.uabb-info-list-top
  li
  .uabb-info-list-icon {
  float: none;
  margin: 0 auto;
}
.uabb-info-list-content-wrapper.uabb-info-list-top
  .uabb-info-list-icon,
.uabb-info-list-content-wrapper.uabb-info-list-top
  .uabb-info-list-content {
  display: block;
}
.uabb-info-list-icon {
  position: relative;
  z-index: 5;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-imgicon-wrap {
  text-align: center;
}

.uabb-info-list-item
  .uabb-info-list-icon
  .uabb-icon-wrap {
  vertical-align: middle;
}
.uabb-icon-wrap {
  display: inline-block;
}

.uabb-imgicon-wrap .uabb-icon {
  display: block;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i,
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}
.uabb-imgicon-wrap .uabb-icon i {
  float: none;
}
.uabb-imgicon-wrap .uabb-icon i {
  float: none;
}
.uabb-imgicon-wrap .uabb-icon i {
  float: none;
}
.fl-node-5bad6f2334e50 .uabb-icon i {
  float: none;
}
.uabb-imgicon-wrap .uabb-icon i {
  margin: 0;
}
.uabb-icon i {
  display: block;
}
.uabb-icon i {
  display: block;
}
.uabb-icon i {
  display: block;
}
.fl-builder-content *,
.fl-builder-content *:before,
.fl-builder-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ua-icon {
  font-family: "Ultimate-Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uabb-imgicon-wrap .uabb-icon i {
  float: none;
}

.ua-icon-phone-handset:before {
  content: "\260E" !important;
}

.uabb-icon i:before {
  border: none !important;
  background: none !important;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  background: none;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i,
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}

.fl-clearfix {
  zoom: 1;
}

.fl-clearfix:before,
.fl-clearfix:after {
  display: table;
  content: " ";
}

.uabb-info-list-content-wrapper {
  position: relative;
  z-index: 5;
}
.fl-clearfix {
  zoom: 1;
}
.fl-builder-content *,
.fl-builder-content *:before,
.fl-builder-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fl-node-5bad6f2334e50
  .uabb-info-list-wrapper.uabb-info-list-top
  li
  .uabb-info-list-connector {
  left: calc(50% + 36px);
  width: calc(100% - 72px);
  top: 36px;
}
.fl-node-5bad6f2334e50
  .uabb-info-list-wrapper.uabb-info-list-top
  li
  .uabb-info-list-connector {
  border-top-width: 1px;
}
.uabb-info-list-wrapper.uabb-info-list-top
  li
  .uabb-info-list-connector {
  height: 0;
  left: 50%;
  margin-left: 0;
  position: absolute;
  width: 100%;
}
.fl-node-5bad6f2334e50
  .uabb-info-list-connector {
  top: calc(50% + 31px);
  height: calc(50% - 31px);
}
.fl-node-5bad6f2334e50
  .uabb-info-list-connector,
.fl-node-5bad6f2334e50
  .uabb-info-list-connector-top {
  color: #39618e;
  border-style: dashed;
}
.uabb-info-list-connector,
.uabb-info-list-connector-top {
  height: 100%;
  position: absolute;
  z-index: 1;
  border-width: 0 0 0 1px;
}

p.text-center.mt-3.ourServicesFont {
  color: #222222;
}

.container.vizual
  > div:nth-child(4)
  > h1
  > div
  > p {
  margin-top: 15px;
}

#inputPassword4 {
  width: 97%;
}

@media screen and (min-width: 768px) {
  .fl-node-5bad6f2334e50
    .uabb-info-list-wrapper.uabb-info-list-top
    li {
    width: 33.333%;
    display: inline-block;
  }
  .fl-node-5bad6f2334e50
    .uabb-info-list-wrapper
    .uabb-info-list-item {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .service-gallery {
    grid-template-columns: repeat(
      auto-fit,
      minmax(272px, 1fr)
    );
  }
  #inputPassword4 {
    width: 102%;
  }
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  background: none;
}
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i,
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}
.uabb-icon i:before {
  border: none !important;
  background: none !important;
}
.uabb-icon i:before {
  border: none !important;
  background: none !important;
}
.uabb-icon i:before {
  border: none !important;
  background: none !important;
}
.fl-builder-content *,
.fl-builder-content *:before,
.fl-builder-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ua-icon-phone-handset:before {
  content: "\e9b2";
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.uabb-info-list
  .uabb-info-list-wrapper
  > li {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #777777;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic1
  .uabb-icon-wrap
  .uabb-icon
  i,
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic1
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}
.uabb-imgicon-wrap .uabb-icon i {
  float: none;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic1
  .uabb-imgicon-wrap {
  text-align: center;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic2
  .uabb-icon-wrap
  .uabb-icon
  i,
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic2
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}
.uabb-imgicon-wrap .uabb-icon i {
  float: none;
}

.ua-icon {
  font-family: "Ultimate-Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ua-icon-map-marker:before {
  content: "\e9a6";
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic2
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  background: none;
}
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic2
  .uabb-icon-wrap
  .uabb-icon
  i,
.fl-node-5bad6f2334e50
  .info-list-icon-dynamic2
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}
.uabb-icon i:before {
  border: none !important;
  background: none !important;
}
.uabb-icon i:before {
  border: none !important;
  background: none !important;
}
.uabb-icon i:before {
  border: none !important;
  background: none !important;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic2
  .uabb-imgicon-wrap {
  text-align: center;
}

.fl-module.fl-module-info-list.fl-node-5bad6f2334e50 {
  width: 85%;
  text-align: center;
  margin: 50px auto !important;
}
/* 
#root
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div
  > ul
  > li.uabb-info-list-item.info-list-item-dynamic2 {
  position: inherit;
} */

.findLocation p {
  margin: auto;
}

#root
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div
  > div
  > ul
  > li.uabb-info-list-item.info-list-item-dynamic2
  > div.uabb-info-list-connector.uabb-info-list-top {
  display: none;
}

.uabb-info-list-content-wrapper.fl-clearfix.uabb-info-list-top
  h3 {
  margin-top: 15px;
  font-size: 20px;
}

.fl-node-5bad6f2334e50
  .info-list-icon-dynamic0
  .uabb-icon-wrap
  .uabb-icon
  i:before {
  color: #2978b0;
  font-size: 30px;
  height: auto;
  width: auto;
  background: #ffffff;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  line-height: 70px;
  height: 70px;
  width: 70px;
  text-align: center;
  border-style: solid;
  box-sizing: border-box;
  border-color: #39618e;
  border-width: 1px;
}

i.ua-icon.ua-icon-envelope2::before {
  content: "\2709";
  font-size: 41px !important;
  font-weight: 900;
}
.ua-icon-map-marker:before {
  content: "\272C" !important;
  font-size: 37px !important;
  font-weight: 700;
}

.findLocation {
  width: 90%;
  margin: auto;
  padding: 50px 0 !important;
  text-align: justify !important;
}

.contact-wrapper {
  background: url("./img/contact-cover.png")
    center/cover no-repeat !important;
}

.contact-wrapper .container-sm {
  width: 60%;
  padding: 50px;
}

input {
  font-size: 14px !important;
  font-family: var(--mainText);
}

textarea.form-control {
  height: auto;
  font-family: var(--mainText);
  font-size: 15px;
}

#root
  > div:nth-child(2)
  > div:nth-child(2)
  > div.contact-wrapper
  > div
  > form
  > button {
  font-size: 15px;
  margin-top: 24px;
  padding: 15px 25px !important;
  border-radius: 6px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

h3.white,
h5.white {
  color: #ffffff;
}

h3.white {
  margin-bottom: 30px;
}

#root
  > div
  > div:nth-child(3)
  > div.fl-module.fl-module-info-list.fl-node-5bad6f2334e50
  > div
  > div
  > ul
  > li.uabb-info-list-item.info-list-item-dynamic2
  > div.uabb-info-list-connector.uabb-info-list-top {
  display: none !important;
}

#root
  > div
  > div:nth-child(3)
  > div.contact-wrapper
  > div
  > form
  > button {
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 7px 20px !important;
  font-family: var(--mainText);
  border-color: transparent;
  margin-top: 30px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 3px !important;
}
.serviceContent {
  color: #222222;
}

div.button-fixed
  > div
  > div
  > div:nth-child(2)
  > div
  > button {
  margin-top: 27px;
  padding: 10px;
  font-family: var(--mainText);
  text-transform: uppercase;
}

.who-we-are {
  padding: 50px 0 !important;
  padding-bottom: 0 !important;
}
ul.pagination {
  width: 280px;
  margin: auto;
  justify-content: center;
  padding: 30px 0;
}

#root
  > div
  > div:nth-child(3)
  > div.who-we-are
  > .row {
  margin: 0 !important;
}

ul.row {
  margin-top: 0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #2978b0 !important;
  border-color: #2978b0 !important;
}
.who-we-are p {
  text-align: justify;
  margin-bottom: 50px !important;
}

li.list-group-item {
  border: none;
  padding-left: 8px;
}
#root > div > div:nth-child(3) > nav {
  margin-bottom: 50px;
}

.center-map {
  width: 100%;
  text-align: center;
  margin: auto;
  padding-bottom: 50px;
}

.center-map img {
  width: 1000px;
}

a:hover {
  text-decoration: none !important;
}

#root
  > div:nth-child(2)
  > div.subscribe
  > div
  > form
  > button {
  padding: 11px;
}
#root
  > div:nth-child(2)
  > div.subscribe
  > div
  > form
  > button:hover {
  color: #ffffff;
  background-color: #cb0303 !important;
}

button.close:hover {
  color: #2978b0 !important;
  background: transparent !important;
}
button.close {
  float: right;
  position: absolute;
  right: 20px;
  top: 9px;
  font-size: 37px;
}

button.form-time-pick-button {
  float: right;
}

.book-now-datetime {
  width: 90%;
}

button.form-time-pick-button {
  float: right;
  right: 55px;
  position: relative;
}

.form-time-pick-container {
  position: relative;
}

.form-time-pick-container {
  text-align: justify !important;
  float: left;
  width: 159px;
  left: 54px;
}

select.form-control {
  width: 108px;
  position: relative;
  left: 10px;
}

button.text-uppercase.bg:hover {
  background: #2978b0;
  color: #ffffff;
}

#root
  > div
  > div:nth-child(4)
  > div.got-you-covered
  > div.about-description
  > button {
  width: auto !important;
  margin-top: 28px !important;
  padding: 10px;
}

form.subscribe-container button {
  padding: 11px;
}

a.book-an-appointment.btn.btn-danger.text-uppercase:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.otherServices {
  background: url("./img/subscribe-bg.jpg")
    center/cover no-repeat;
  color: #fff;
  font-family: var(
    --mainText
  ) !important;
  text-transform: uppercase;
  padding: 50px;
}

.otherServices h2 {
  text-align: center;
  width: 77%;
  margin: auto;
  margin-bottom: 30px;
}
.otherDiv {
  width: 88%;
  margin: auto;
  display: flex;
  padding-left: 40px;
}

.otherDiv > ul {
  margin-right: auto;
  display: flex;
  width: 70%;
  padding: 0;
  margin: auto !important;
  font-size: 20px;
}

.otherDiv > ul > div {
  width: fit-content;
  margin: auto;
}

.otherServices h5 {
  color: #fff;
}

.row.justify-content-center h2 {
  font-size: 28px;
}

#root
  > div
  > div:nth-child(3)
  > div.container-fluid.mb-5.my-15 {
  margin-top: 0px !important;
}

.otherDiv > ul {
  margin-right: auto;
  padding: 0;
  margin: auto !important;
  font-size: 20px;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(88px, 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.sl-nav-logo {
  width: 140px;
}

nav.sl-navbar i.fa.fa-phone {
  display: none;
}
#root
  > div
  > div:nth-child(3)
  > div.container-fluid.mb-5.my-15 {
  margin-bottom: 85px !important;
}

p.text-center.text-capitalize.mainText {
  font-size: 16px;
  color: #222222 !important;
  text-align: justify !important;
}

.service-weOffer .service-gallery {
  margin: auto;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(331px, 1fr)
  ) !important;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.otherDiv {
  width: 100%;
  margin: auto;
  display: flex;
  padding-left: 0px;
}

.otherDiv li {
  font-size: 15px;
}

.otherDiv > ul {
  margin-right: auto;
  padding: 0;
  margin: auto !important;
  font-size: 17px;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(88px, 1fr)
  );
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.service-weOffer .service-gallery {
  grid-row-gap: 48px;
}

.footer-logo-img img {
  width: 40px;
  display: inline-block;
}

uppercase.compet {
  font-size: 18px;
}

.footer-logo-img img:nth-child(2) {
  width: 80px;
  position: relative;
  top: -10px;
}

.footer-logo-img img {
  width: 65px;
  display: inline-block;
  margin-right: 15px;
}

h4.mainText.text-uppercase.compet {
  font-size: 17px;
}

#root
  > div.gallery-container.mt-5
  > div {
  width: 100%;
  height: 184px;
  overflow: hidden;
}

.box-container h1 {
  color: var(--mainWhite);
  font-family: var(--mainText);
  font-size: 42px;
}
#root
  > header
  > div.banner-container
  > div
  > h2:nth-child(3)
  > br {
  display: none;
}

h2.text-uppercase {
  color: var(--mainWhite);
  font-family: var(--mainText);
  font-size: 35px;
}

.box-container p {
  font-size: 28px;
}

.footer-logo-img {
  margin-top: 15px;
  opacity: 0.6;
}

.footer-box-container {
  padding-bottom: 50px;
}

.our-service-gallery img,
#root
  > div:nth-child(3)
  > div.container-fluid.mb-5
  > div
  > a:nth-child(5)
  > img {
  width: 100%;
  height: 258px;
}
h3.drp-main-blue {
  color: var(--mainBlue) !important;
}
.wgy-covered {
  display: none;
}
@media screen and (max-width: 1400px) {
  .wgy-covered {
    position: relative;
    top: -20px;
    display: block;
    margin: auto;
    width: 220px;
  }
  .wgy-covered img {
    width: 100%;
  }
  .banner-container {
    transform: translate(25%);
  }

  .column.footer-box {
    height: auto !important;
  }

  .col.footer-copy-right.text-light {
    margin: auto;
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  #root
    > header
    > div.banner-container
    > div
    > h2:nth-child(3)
    > br {
    display: block;
  }

  #root
    > div
    > div:nth-child(3)
    > div.container-fluid.mb-5.my-15 {
    margin-bottom: 50px !important;
  }

  .center-map {
    width: 100%;
    text-align: center;
    margin: auto;
    padding-bottom: 50px;
  }

  .center-map img {
    width: 100%;
  }

  .who-we-are .row {
    margin: auto;
  }

  ul.row {
    padding: 0;
  }
  img.serviceImage {
    width: 100%;
    margin-top: 30px;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    appearance: none;
    top: 15px;
    right: 18px;
  }

  .banner {
    transform: translate(0%) !important;
  }

  .container-fluid {
    width: 100%;
    display: block !important;
  }

  nav.navbar.fixed-top.navbar-expand-xl.navbar-light.bg-color-light.px-sm-5 {
    width: 100%;
    margin: auto;
    display: block !important;
    position: fixed;
  }

  span.navbar-toggler-icon {
    position: absolute;
    right: 0;
  }

  .footer-box-container {
    width: 100%;
    margin: auto;
    display: block;
  }

  .banner-container {
    transform: initial !important;
    margin: auto !important;
  }

  .banner-container {
    width: 100%;
    display: block !important;
    margin: auto;
    position: relative;
    float: none;
    padding: 0 !important;
  }

  .subscribe-box {
    width: 100%;
  }

  .box-container {
    margin: auto;
    display: block;
  }

  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
    background: none !important;
  }

  button.navbar-toggler.rad.collapsed:hover {
    background: none !important;
  }

  .otherDiv > ul {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(131px, 1fr)
    );
    align-items: center;
  }

  .box-container p {
    color: var(--mainWhite);
    font-size: 1.4rem !important;
    margin: auto !important;
    line-height: 1.7rem;
    margin-top: 10px !important;
  }

  .btn-danger {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    padding: 8px 15px !important;
    font-family: var(--mainText);
    border-color: transparent;
    display: block;
    margin: auto;
  }

  .banner {
    height: 450px !important;
    width: 100%;
  }

  .defaultHero {
    height: 450px !important;
    width: 100%;
    background: url("./img/sl-roof-banner.jpg")
      center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 4rem !important;
  }

  .our-service-gallery div {
    margin: auto;
    width: 100%;
  }

  .hover-button {
    opacity: 1;
    position: absolute;
    top: 0%;
    background: none !important;
    background: no-repeat;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  a.sbook-now {
    background: #2978b0 !important;
    color: #ffffff;
  }

  .column.footer-box {
    margin: auto;
    display: block;
    width: 225px !important;
  }

  button.navbar-toggler.rad {
    right: 0 !important;
    position: absolute;
    top: 25px !important;
  }
  .icon-logo img {
    width: 6rem !important;
  }

  .defaultHero {
    margin-top: 4rem !important;
  }

  .box-container h1 {
    font-size: 2rem !important;
    padding: 0 !important;
  }

  h2.text-uppercase {
    color: var(--mainWhite);
    font-size: 24px;
    font-family: var(--mainText);
    margin-top: 15px;
  }

  .box-container {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .footer-container {
    background: var(--secondDark);
    width: 100%;
    height: auto;
    margin: auto;
    padding-top: 1px;
  }
  .footer-box-container {
    width: 90%;
    margin: auto;
    display: block;
  }
  p.text-capitalize.text-center {
    margin-bottom: 0;
    text-align: center;
    display: block;
    margin: auto !important;
    width: 80%;
    padding-bottom: 10px !important;
  }
  .col.footer-copy-right.text-light {
    margin: auto;
  }
  .column.footer-box {
    margin-bottom: 15px;
  }
  div#carouselExampleIndicators {
    height: auto !important;
  }
  h1.text-center.text-capitalize.mainText {
    width: 85%;
    margin: auto;
  }

  .footer-box {
    margin-bottom: 30px !important;
  }
  p.text-center.w-50.mx-auto {
    width: 75% !important;
  }
  .subscribe-box {
    padding: 50px 0 !important;
  }
  .subscribe-container
    input[type="text"] {
    font-family: var(--mainText);
    width: 170px;
    padding: 6px;
    margin-top: 0px;
    font-size: 17px;
    border: none;
  }
  .sbook-now,
  .slearn-more {
    position: relative;
    left: auto !important;
    margin: auto;
    text-align: center;
    display: block;
    width: 80% !important;
    padding: 4px !important;
    border-radius: 10px;
    font-size: 1rem;
    color: #2978b0;
    background: #ffffff;
    text-transform: uppercase;
    font-family: var(--mainText);
    top: 54%;
  }
  .sbook-now {
    width: 80% !important;
  }
  a.sbook-now.width-book {
    width: 80% !important;
  }
  a.slearn-more {
    margin-top: 10px !important;
    padding-left: 17px;
  }
  .banner-container {
    width: 100%;
    left: 0;
  }

  div#navbarSupportedContent {
    float: right;
    top: 10px;
    position: relative;
  }

  div#navbarSupportedContent {
    float: right;
    top: 10px;
    position: relative;
  }

  p.text-capitalize.text-center {
    padding: 19px !important;
    margin-top: 50px !important;
    text-align: justify !important;
    left: 0;
    position: relative;
    display: block;
  }
  .carousel-inner.container-box {
    width: 100%;
  }
  .container-box.text-light {
    width: 100%;
    margin-top: 50px;
  }
  span.navbar-toggler-icon {
    right: 20px;
    top: 0px;
  }
  p.text-capitalize.text-center {
    width: 100%;
    text-align: center !important;
  }

  .banner {
    background: none;
    background-color: #00000073;
  }
  a.sbook-now:hover {
    background-color: #ff0000 !important;
    color: #ffffff !important;
  }
  a.slearn-more:hover {
    color: #ffffff !important;
    background: #2978b0;
  }

  div#navbarSupportedContent {
    float: right;
    width: 60% !important;
  }
  #root
    > div:nth-child(2)
    > div:nth-child(2)
    > div.container.mx-15
    > div
    > div {
    display: none !important;
  }
  h1 {
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--mainText);
    padding: 2px 0;
  }
  .our-service-gallery {
    margin: auto;
    width: 100%;
  }

  /*--about us*/

  h1.text-uppercase.text-center {
    font-size: 40px;
    padding: 30px 0px 20px 0;
  }

  h3.text-capitalize.text-center {
    margin-top: 15px;
    font-size: 25px;
    width: 80%;
    margin: auto;
    margin-top: 10px;
  }
  .left-cov {
    width: 100%;
    vertical-align: top;
    margin: auto;
  }
  .about-description {
    width: 90%;
    display: block;
    /*padding: 50px 0px;*/
    margin-top: 10px;
    margin: auto;
  }
  .the-owner {
    width: 90%;
    margin: auto;
    color: #fff;
    padding: 50px 0;
  }
  .who-we-are {
    margin: auto;
    width: 90%;
    padding: 50px 0;
    text-align: center;
    padding-bottom: 0px;
  }
  .findLocation {
    margin: auto;
    padding: 50px 0;
  }

  .my-15 {
    margin: 50px auto !important;
    width: 90% !important;
  }

  .about-description {
    width: 90%;
    padding: 50px 0;
  }
  h5,
  h3 {
    text-align: center;
  }
  .container-fluid.mb-5 {
    padding: 0;
  }
  nav.sl-navbar {
    padding: 0px;
  }

  .main-blue {
    text-align: justify;
    font-size: 17px;
  }
  .blog-category-cointainer h1 {
    font-size: 2rem;
  }
  #root
    > div:nth-child(2)
    > div:nth-child(3)
    > div.my-15 {
    margin-bottom: 50px !important;
  }
  .blog-category-cointainer {
    width: 85%;
  }
  nav.sl-navbar {
    background: #ffffff;
  }

  #root
    > nav
    > div
    > ul
    > div
    > li
    > button {
    font-size: 1.2rem;
    padding: 0.3rem;
    width: 12rem;
    border-radius: 10px !important;
    background: var(--mainRed);
    color: var(--mainWhite);
    font-family: var(--mainText);
    margin: auto !important;
    position: relative;
    top: 0px;
  }

  .sl-nav-links {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.5s linear;
    list-style-type: none;
    position: relative;
    top: 0px;
  }
  .sl-nav-center {
    height: 60px;
  }

  #root
    > div.footer-container
    > div.footer-box-container
    > div.container-box.text-light
    > div:nth-child(3) {
    margin-top: 50px;
  }
  .hover-button-services {
    display: none !important;
  }
  #root
    > div:nth-child(2)
    > div.subscribe
    > div
    > form
    > button {
    padding: 7px;
  }
  form.subscribe-container button {
    padding: 7px !important;
  }
  .otherDiv {
    width: 100%;
    margin: auto;
    display: flex;
    padding-left: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .sl-nav-center i.fa.fa-phone {
    transform: rotate(114deg);
  }

  .fl-module-content {
    margin-bottom: 0 !important;
  }
  .fl-module.fl-module-info-list.fl-node-5bad6f2334e50 {
    margin: 25px auto !important;
  }

  .sl-nav-logo {
    margin: auto;
  }

  .contact-wrapper .container-sm {
    width: 90%;
    padding: 50px 0;
  }

  p.text-center.w-50.mx-auto {
    width: 90% !important;
  }

  .fa-arrow-left:before {
    font-size: 16px !important;
    position: relative;
    left: -18px;
  }

  .fa-arrow-right:before {
    content: "\f061";
    font-size: 16px !important;
    position: relative;
    right: -18px;
  }

  .button-fixed {
    position: fixed;
    z-index: 1000;
    bottom: 1px;
    width: 100%;
    padding: 9px 0;
    background: #ffffffb5;
    display: block;
  }

  .hover-button {
    display: none;
  }

  .hold-services:hover,
  .hold-services p:hover {
    color: #2978b0 !important;
  }

  p.text-center.mt-3.ourServicesFont {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: auto;
    margin: auto !important;
    color: #ffffff !important;
    font-size: 1.2rem;
    background: #3e85b673;
    padding: 11px;
    text-transform: uppercase;
    right: 0;
    left: 0;
    bottom: 41px;
  }

  a.slearn-more {
    display: none;
  }

  #root
    > div.footer-container
    > div.button-fixed
    > button {
    background: var(--secondaryColor);
    border: none;
    width: 90% !important;
    display: block;
    margin: auto;
    float: none !important;
    padding: 10px;
    font-size: 1rem !important;
    text-align: center;
    position: relative;
    left: 0;
    margin-right: auto !important;
    right: 0;
    margin-left: auto !important;
  }

  p.text-capitalize.text-center {
    margin-top: 0px !important;
  }
  /*the owner*/
  .owner-description {
    width: 100%;
    display: block;
    margin: auto;
  }
  .owner-image {
    display: none;
  }

  .owner-image-two {
    display: block;
  }
  .owner-image-two img {
    width: 100%;
    margin-top: 30px;
  }
  .owner-description h3 {
    text-align: center;
  }
  .blogCategStyle {
    position: absolute;
    right: 0;
    color: white;
    text-transform: uppercase;
    width: 175px;
    top: 210px;
    padding: 5px 28px;
    background: #ffffff33;
  }
  .sl-nav-links {
    background: #ffffff !important;
    top: 0px !important;
  }
  .footer-container
    button.btn-book.text-uppercase.mx-3.rad {
    display: block;
    width: 90% !important;
  }
  ul.sl-nav-links.sl-show-nav
    button.btn-book.text-uppercase.mx-3.rad {
    display: none;
    
  }

  .box-container
    .button-container.book-an-appointment {
    display: none;
  }
  .container.vizual {
    max-width: 100% !important;
    background: none !important;
  }
  .defaultHero {
    height: 629px;
    width: 100%;
    background: url("./img/sl-roof-banner0.jpg")
      center/cover no-repeat;
  }
  .banner {
    background-color: rgb(
      0 0 0 / 25%
    ) !important;
  }
  span.contact {
    margin-left: auto;
    position: relative;
    right: 20px;
  }
  .hold-services {
    position: relative;
    cursor: pointer;
    height: 213px;
    margin: 15px 0;
  }
  nav.sl-navbar i.fa.fa-phone {
    display: inline-block;
  }
  .otherDiv li {
    font-size: 16px;
  }
  .our-service-gallery img,
  #root
    > div:nth-child(3)
    > div.container-fluid.mb-5
    > div
    > a:nth-child(5)
    > img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .box-container
    .button-container.book-an-appointment {
    padding: 8px 15px !important;
    width: 30%;
    margin: auto;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .login-container {
    left: 50%;
    padding: 30px 15px;
    height: 378px !important;
    padding: 23px !important;
  }

  .sl-nav-links {
    padding: 0;
  }
  .sl-nav-header {
    margin-top: 0px;
  }
  .sl-nav-links {
    background: #fffffff2;
    position: absolute;
    height: 90vh;
    right: 0px;
    text-transform: uppercase;
    display: block;
    font-family: var(--mainText);
    flex-direction: column;
    justify-content: center;
    transform: translate(100%);
    top: 60px !important;
  }

  li.active {
    text-align: center !important;
  }
  .sl-nav-links .nav-item {
    display: block;
    width: max-content;
    text-decoration: none;
    transition: all 0.3s ease;
    color: var(--mainDark) !important;
    font-size: 1rem;
    font-weight: 600;
    margin: auto;
    text-align: center;
    padding-top: 15px;
  }
  .sl-nav-logo  {
    width: 110px;
    margin-top: 0px;
  }
  .login-container {
    left: 50%;
    height: 90%;
    padding-top: 30px;
  }
  .service-weOffer .service-gallery {
    grid-template-columns: repeat(
      auto-fit,
      minmax(277px, 1fr)
    ) !important;
  }
}

@media screen and (max-width: 1041px) and (min-width: 995px) {
  .sbook-now,
  .slearn-more {
    top: 54%;
  }

  li.active {
    text-align: center !important;
  }
  .sl-nav-links .nav-item {
    display: block;
    width: max-content;
    text-decoration: none;
    transition: all 0.3s ease;
    color: var(--mainDark);
    font-size: 1rem;
    font-weight: 600;
    margin: auto;
    text-align: center;
    padding-top: 15px;
  }
  .form-time-pick-gird {
    font-size: 14px;
  }
  .form-time-pick-gird {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(50px, 100px)
    );
    /* grid-column-gap: 20px; */
    /* grid-row-gap: 20px; */
    grid-auto-rows: 35px;
  }
  .form-time-pick-gird {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(50px, 100px)
    );
    grid-row-gap: 12px;
    grid-auto-rows: 35px;
  }

  .gallery-container {
    margin: auto;
    width: 85%;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(210px, 1fr)
    );
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .gallery-box {
    width: 320px;
  }
  .gallery-box img {
    width: 100%;
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    appearance: none;
    top: 15px;
    right: 18px;
  }

  div.button-fixed
    > div
    > div
    > div:nth-child(3)
    > div {
    width: 36%;
    text-align: justify !important;
    position: relative;
    top: 7px;
    left: 0;
  }
}
@media screen and (max-width: 800px) {
  .hover-button {
    height: 86% !important;
  }
  .button-container {
    display: none;
  }

  .container-box.text-light {
    margin: auto;
    width: 80% !important;
    display: block;
    text-align: center;
    padding-top: 50px;
  }

  .column.footer-box {
    margin-top: 0px;
  }
  span.navbar-toggler-icon {
    right: 20px;
    top: 0px;
  }

  p.text-capitalize.text-center {
    width: 100%;
  }
  .sbook-now,
  .slearn-more {
    top: 38%;
  }
  .carousel-inner.container-box {
    margin-top: 0;
  }
  .sbook-now,
  .slearn-more {
    top: 52%;
  }
  .uabb-info-list-connector.uabb-info-list-top {
    display: none;
  }
  .uabb-info-list-content-wrapper.fl-clearfix.uabb-info-list-top {
    padding-bottom: 30px;
  }

  .container-box.text-light
    .column.footer-box:nth-child(3) {
    margin-top: 50px !important;
  }

  div.button-fixed
    > div
    > div
    > div:nth-child(3)
    > div {
    width: 25%;
    text-align: justify !important;
    position: relative;
    top: 7px;
    left: 58px;
  }
}
/**/
@media screen and (max-width: 696px) {
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 5px;
    background-color: #fff;
    border: 1px solid
      rgba(0, 0, 0, 0.125);
  }
  .sbook-now,
  .slearn-more {
    top: 54%;
  }
  #root
    > div.footer-container
    > div
    > div.container-box.text-light
    > div:nth-child(3)
    > h4.text-uppercase.mainText {
    margin-top: 60px;
  }
  .sl-nav-links {
    width: 100%;
    padding: 0;
  }
  /* .sl-nav-links {
    background: #fffffff2;
    position: absolute;
    height: 90vh;
    top: 95px !important;
    right: 0px;
    text-transform: uppercase;
    display: block;
    font-family: var(--mainText);
    flex-direction: column;
    justify-content: center;
    transform: translate(100%);
  } */

  .nav-active {
    color: var(--mainBlue) !important;
    font-size: 18px !important;
  }
  li.active {
    text-align: center !important;
  }
  .sl-nav-links .nav-item {
    display: block;
    width: max-content;
    text-decoration: none;
    transition: all 0.3s ease;
    color: var(--mainDark);
    font-size: 1rem;
    font-weight: 600;
    margin: auto;
    text-align: center;
    padding-top: 15px;
  }
 
  #root
    > div:nth-child(2)
    > div:nth-child(3)
    > div.got-you-covered
    > div.about-description
    > button {
    display: none;
  }
  .ownerCover {
    background: #2978b0;
    height: 725px;
    margin-bottom: 150px;
  }
}
@media screen and (max-width: 650px) {
  .sbook-now,
  .slearn-more {
    top: 50%;
  }
  p.text-center.w-50.mx-auto {
    width: 90% !important;
    height: 255px;
  }

  div.button-fixed
    > div
    > div
    > div:nth-child(3)
    > div {
    width: 27% !important;
    margin: auto;
    text-align: justify !important;
  }

  button.form-time-pick-button {
    float: right;
    right: 22px;
    position: relative;
  }
  div.button-fixed
    > div
    > div
    > div:nth-child(3)
    > div {
    width: 29%;
    margin: auto;
    text-align: justify !important;
    position: relative;
    top: 11px;
  }

  .book-now-modal-container {
    height: 71%;
  }

  div.button-fixed
    > div
    > div
    > div:nth-child(2)
    > div
    > button {
    margin-top: 0px;
    padding: 10px;
    font-family: var(--mainText);
    text-transform: uppercase;
  }

  button.form-time-pick-button {
    float: right;
    right: 37px;
    position: relative;
  }
  .login-container {
    max-width: 95%;
  }

  .form-info-buttons {
    margin: auto;
    position: absolute;
    bottom: 14px;
    right: 30px;
  }
  button.form-time-pick-button {
    float: right;
    right: 41px;
    position: relative;
  }
  .box-container h1 {
    font-size: 22px !important;
    padding: 0 !important;
  }

  h2.text-uppercase {
    color: var(--mainWhite);
    font-size: 21px !important;
    font-family: var(--mainText);
    margin-top: 15px;
  }
  .banner-container strong {
    font-size: 18px;
  }
}

@media screen and (max-height: 390px) {
  div.button-fixed
    > div
    > div
    > div:nth-child(2)
    > div
    > button {
    margin-top: 0;
  }
  #root
    > main
    > div
    > form
    > div.form-group.text-center.mt-3
    > button {
    width: 104px;
    padding: 6px 0px !important;
    position: relative;
    top: -18px;
    right: 0;
    margin-top: 0;
  }
  .form-group.text-center.mt-3 {
    height: 49px;
    margin: 0;
  }
  .login-container {
    width: 85%;
    height: 290px !important;
    padding: 15px !important;
  }
  .form-group {
    margin-bottom: 0;
    margin-top: 4px;
  }
  .login-container h2 {
    font-size: 1.5rem !important;
  }
  .book-now-modal-container {
    height: 360px !important;
  }
  button.close {
    top: -3px;
    right: 5px;
  }
}

@media screen and (max-width: 569px) and (min-width: 523px) {
  .sbook-now,
  .slearn-more {
    top: 68%;
  }
}

@media screen and (max-width: 524px) {
  .login-container {
    padding: 70px 15px;
  }
  .banner-container {
    transform: translate(37%);
  }
  .banner {
    transform: translate(0%) !important;
    width: 100% !important;
  }
  .phone-number {
    font-family: var(--mainText);
    color: var(--mainRed) !important;
  }
  .banner-container {
    width: 100%;
    left: 0;
  }
  .our-blog-gallery {
    grid-template-columns: repeat(
      auto-fit,
      minmax(300px, 1fr)
    );
    grid-auto-rows: 300px;
  }
  .our-blog-text {
    margin-top: 150px;
  }
  button.close {
    top: 2px;
  }
  .form-info-buttons {
    margin: auto;
    position: absolute;
    bottom: 35px !important;
    right: 42px !important;
  }
  .sl-admin-links {
    width: 48% !important;
  }
  .otherDiv > ul {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(98px, 1fr)
    ) !important;
    align-items: center;
    font-size: 14px;
  }
  .owner-image-two img {
    width: 90%;
    margin-top: 30px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 476px) and (min-width: 393px) {
  .hover-button {
    height: 82%;
  }
}

@media screen and (max-width: 392px) {
  .our-blog-gallery {
    grid-template-columns: repeat(
      auto-fit,
      minmax(280px, 1fr)
    );
    grid-auto-rows: 280px;
  }
  .our-blog-text {
    margin-top: 150px;
  }
  .hover-button {
    opacity: 1;
    position: absolute;
    top: 0%;
    height: 78%;
  }

  p.text-capitalize.text-center {
    margin-bottom: 0;
    width: 100%;
  }
  .sbook-now,
  .slearn-more {
    top: 48%;
  }
}
@media screen and (max-width: 330px) {
  .box-container h1 {
    font-size: 2rem !important;
    padding: 0 !important;
  }

  .box-container {
    width: 100%;
  }
}

@media screen and (min-height: 415px) {
  nav > div > ul > li.active {
    padding: 20px 0;
  }
}

@media screen and (max-width: 415px) {
  button.form-time-pick-button {
    float: right;
    right: 23px !important;
    position: relative;
  }
}

@media screen and (max-width: 320px) {
  .service-gallery {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: none !important;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

@media screen and (max-width: 1000px) and (min-width: 745px) {
  .our-service-gallery {
    margin: auto;
    width: 85%;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(233px, 1fr)
    ) !important;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  p.text-center.mt-3.ourServicesFont {
    bottom: 65px !important;
  }
}
