.roofingImg {
  height: auto;
  margin: 10px 3px;
  display: "flex";
  flex-direction: "column";
}
.default-image {
  max-height: 768px;
  width: 924px;
}

.default-image img {
  height: 792px;
  width: 100%;
}

.second-image {
  max-height: 768px;
  width: 924px;
  overflow: hidden;
}

.second-image img {
  height: 792px;
  width: 100%;
}
.vizual-wrapper {
  display: flex;
}

.container.vizual h2 {
  margin-bottom: 30px;
}

.roofing-list {
  width: 150px;
  height: 792px;
  overflow: auto;
  border: 2px solid #9e9b9b;
}

.container.vizual p {
  font-family: var(--mainText) !important;
  font-size: 25px;
  color: #222222;
  padding-top: 5px;
}

.row {
  margin: 40px auto;
  margin-bottom: 0;
}

.who-we-are {
  padding-bottom: 0;
}

.container.vizual {
  padding: 50px 0;
}

@media screen and (max-width: 1200px) {
  .vizual-wrapper {
    display: block;
  }
  .roofing-list {
    width: 100% !important;
    display: flex;
    overflow: auto;
    border: 2px solid #9e9b9b;
    margin: 30px 0px;
    height: 100%;
  }

  .default-image img,
  .second-image,
  .second-image img,
  .default-image,
  .default-image img {
    width: 100% !important;
    height: auto !important;
  }
  .roofingImg img {
    width: 60px;
  }
  .container.vizual {
    max-width: 100% !important;
    background: #2978b021;
    padding: 50px 0;
  }
  .vizual-wrapper {
    display: block;
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  .vizual-wrapper {
    display: block;
  }

  .roofing-list {
    width: 100%;
    height: auto;
    overflow: auto;
  }
}
