.book-now-modal-container {
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  max-width: 800px;
  height: 460px;
  transform: translate(-50%, -50%);
  justify-content: center;
  justify-items: center;
  padding: 30px 20px;
  width: 90% !important;
  align-items: center;
}

.book-now-modal {
  z-index: 100;
  background: rgba(126, 126, 126, 0.6);
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.bookNow-modal-label {
  display: flex;
}

.bookNow-modal-label p {
  margin: 5px;
}
